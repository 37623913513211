@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  /* list-style: none; */
  /* text-decoration: none; */
  outline: none;
  border: none;
  background: none;
  font-family: "Poppins", sans-serif;
  /* font-family: 'Inter', sans-serif; */
}
.ck-editor__editable{
  min-height: 200px;
}

.drop-menu::-webkit-scrollbar {
  display: block !important;
}


::-webkit-scrollbar {
  width: 4px !important;
  transition: all 0.4s ease-in-out !important;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(327.46deg, #004AAD 10.31%, #004AAD 65.49%);
  border-radius: 5px !important;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(327.46deg, #004AAD 10.31%, #004AAD 65.49%);
  border-radius: 5px !important;
  transition: all 0.4s ease-in-out !important;
}

a,
button {
  cursor: pointer;
}

html {
  font-size: 14px;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 0.88rem;
}

:root {
  --color-green: #004AAD;
  --menu: #004AAD;
  --color-white: #ffffff;
  --saidbar: #ffffff;
  --card: #ffffff;
  --color-green-50: #004AAD;
  --color-black: #212529;
  --black: #212529;
  --color-grey: #8e8ea1;
  --color-grey-50: #f9f9f9;
  --color-red: rgba(220, 20, 60);
  --color-red-50: rgba(220, 20, 60, 0.501);
  --radius: 10px;
  --radius-50: 50%;
  --shadow-animate: #68af8d;
  --shadow-login: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --bg: #f9f9f9;
  --shadow-btn: transparent 0px 48px 100px 0px, transparent 0px 48px 100px 0px,
    transparent 0px 48px 100px 0px, transparent 0px 48px 100px 0px;
  --background: #f2f3f7;
  --box-shadow-effect: -6px -6px 12px rgba(255, 255, 255, 0.8),
    4px 4px 12px #b9cce7;
  --box-shadow-effect-inset: box-shadow: inset -4.3659px -4.3659px 8.73179px rgba(255, 255, 255, 0.8), inset 2.61954px 2.61954px 4.3659px rgba(185, 204, 231, 0.8);
}

body {
  background: var(--bg);
}

body.dark {
  --menu: #68af8d;
  --card: #ffffff;
  --color-green: #68af8d;
  --color-white: #ffffff;
  --color-green-50: #00a389;
  --color-black: #ffffff;
  --color-grey: #8e8ea1;
  --color-grey-50: #f9f9f9;
  --color-red: rgba(220, 20, 60);
  --color-red-50: rgba(220, 20, 60, 0.501);
  --radius: 10px;
  --radius-50: 50%;
  --shadow-animate: #68af8d;
  --shadow-login: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --bg: #212529;
  --saidbar: #212529;
  --shadow-btn: rgba(2, 137, 121, 0.7) 0px 4px 16px,
    rgba(2, 137, 121, 0.05) 0px 8px 32px, rgba(2, 137, 121, 0.05) 0px 8px 32px;
}

#dark-svg {
  fill: var(--color-black);
}

button {
  box-shadow: var(--background);
}

h1 {
  font-size: 1.8rem;
  font-weight: 800;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 0.87rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.77rem;
}

.small {
  font-size: 0.75rem;
}

#root {
  /* max-width: 1920px; */
  margin: 0 auto;
  display: block;
}

.radius {
  border-radius: var(--radius);
}

.radius-50 {
  border-radius: 50%;
}

.btn {
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 22px;
  border-radius: 10px;
}

.btn-sm {
  padding: 0.5rem 1rem;
}

.white {
  color: var(--color-white);
}

.black {
  color: var(--color-black);
}

.bg-white {
  background-color: var(--color-white);
}

.bg-green {
  background-color: var(--color-green);
}

.bg-red {
  background-color: var(--color-red);
}

.bg-grey {
  background-color: var(--color-grey);
}

.bg-grey {
  background-color: var(--color-grey);
}

.bg-grey-50 {
  background-color: var(--color-grey-50);
}

.green {
  color: var(--color-green);
}

.grey {
  color: var(--color-grey);
}

.w60 {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
}

.w100 {
  width: 6rem;
  height: 6rem;
}

.w50 {
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.center-content {
  justify-content: center;
}

.center-align {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-grey {
  color: var(--color-grey);
}

.text-green {
  color: var(--color-green);
}

.text-black {
  color: var(--color-black);
}

.absolute {
  position: absolute;
}

.absolute-align {
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center {
  left: 50%;
  transform: translateX(-50%);
}

.relative {
  position: relative;
}

.t-0 {
  top: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}

.between {
  justify-content: space-between;
}

.border {
  border: 2px solid var(--color-grey);
}

.bage {
  width: 30px;
  height: 30px;
  font-weight: 500;
  line-height: 12px;
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.opacity {
  opacity: 0;
}

.title {
  font-weight: 700;
  line-height: 44px;
  color: var(--color-black);
}

.title-text {
  font-weight: 700;
  line-height: 29px;
}

.pointer {
  cursor: pointer;
}

.unset-select {
  user-select: none;
}

.grid {
  display: grid;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

#p0 {
  padding: 0px;
}

/* animate */
.animate-cirlce {
  animation: circle-animation 1s linear infinite;
  transform-origin: center center;
  width: 33px !important;
  height: 33px !important;
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* lofin start */

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url("../../public/images/bg/bg.jpg") center center/cover no-repeat;
}

.login-item {
  padding: 81px 54px;
  box-shadow: var(--shadow-login);
  background: var(--color-white);
  width: 600px;
}

.login-item h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: var(--color-black);
  margin-bottom: 20px;
  text-align: center;
}

.form-input {
  margin-bottom: 32px;
}

.form-input input {
  width: 100%;
  border: 1px solid rgba(142, 142, 161, 0.41);
  border-radius: var(--radius);
  color: var(--color-black);
  font-size: 1.5rem;
  line-height: 22px;
  padding: 15px 22px;
}

.form-input textarea {
  width: 100%;
  border: 1px solid rgba(142, 142, 161, 0.41);
  border-radius: var(--radius);
  color: var(--color-black);
  font-size: 1.5rem;
  line-height: 22px;
  padding: 15px 22px;
  height: 200px;
}

.form-input label {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-grey);
  margin-bottom: 10px;
  display: block;
}

/* layout */
.layout {
  position: relative;
}

.saidbar {
  position: fixed;
  top: 0;
  width: 22rem;
  z-index: 999;
  background: var(--saidbar);
  height: 100vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.4s ease-in-out;
}

.saidbar-icon {
  fill: var(--color-white);
  width: 2rem;
  height: 2rem;
}

.saidbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saidbar-logo img {
  /* width: 6rem; */
  height: 6rem;
  transition: all 0.8s ease-in-out;
}

.saidabr-item {
  padding: 0.5rem 0;
}

.saidabr-item a {
  padding: 0.35rem 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 22px;
  color: var(--color-grey);
  gap: 24px;
}

.saidabr-item a.active {
  font-weight: 600;
  color: var(--color-white);
  background: var(--menu);
}

.contant {
  position: relative;
  left: 22rem;
  padding: 1.3rem;
  width: calc(100% - 22rem);
}

.padding-unset {
  padding: 1rem;
}

.search {
  width: 30rem;
}

.search .form-input {
  margin-bottom: unset;
}

.search .search-icon {
  z-index: 999;
  top: 0.8rem;
  left: 0.8rem;
}

.search-icon svg {
  width: 2rem;
  height: 2rem;
}

.search input {
  padding: 0.9rem 1.5rem 0.9rem 3rem;
  background: transparent;
  backdrop-filter: blur(15px);
}

.contant-user {
  gap: 15px;
}
.contant-user a,
.saidbar-link a,
.user-header>a,
.user-item
{
  text-decoration: none;
}

.contant-user .bage {
  top: -12px;
  right: -5px;
  animation: animate-shadow 2s linear infinite;
}

@keyframes animate-shadow {
  0% {
    box-shadow: 0 0 0 0 rgb(104, 175, 141, 0.7);
  }

  40% {
    box-shadow: 0 0 0 30px rgba(2, 104, 104, 0);
  }

  80% {
    box-shadow: 0 0 0 30px rgba(2, 104, 104, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(2, 104, 104, 0);
  }
}

.contant-avatar {
  gap: 20px;
}

.contant-avatar h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-black);
}

.dark-mode {
  width: 50.88px;
  height: 28px;
  border-radius: 40px;
  cursor: pointer;
}

.dark-mode-item {
  width: 21.12px;
  height: 21.12px;
  top: 1.5px;
  left: 0.9px;
  transition: all 0.4s cubic-bezier(0.32, 2, 0.55, 0.27);
  cursor: pointer;
}

#darkmode {
  cursor: pointer;
}

#darkmode:checked~.dark-mode-item {
  left: calc(100% - (0.9px + 21.12px));
}

/* user profile view */
.user-profile-container {
  border-radius: 2rem;
  padding: 2.5rem;
  background-color: var(--saidbar);
}

.user-profile {
  display: grid;
  grid-template-columns: 20% 80%;
}

.user-detil-all {
  padding-left: 2rem;
}

.user-profile .users>h2 {
  margin-bottom: 40px;
  color: var(--color-black);
}

.detil-rule {
  margin-top: 2rem;
}

.user-layout .detil-rule p {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 22px;
  padding-bottom: 1rem;
}

.user-layout {
  border-right: 0.1rem solid var(--color-grey);
}

.select .view-select {
  padding: 0.8rem;
  border: 1px solid #8e8ea1;
  border-radius: 10px;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1rem;
  color: var(--color-grey);
}

.select .view-select span:first-child {
  min-width: 150px;
}

.select-item-user {
  border-radius: var(--radius);
  box-shadow: var(--shadow-login);
  z-index: 999;
  background: var(--color-white);
  height: 0;
  overflow: hidden;
  top: 60px;
  transition: all 0.4s ease-in-out 0.3s;
}

.select-item-user.active {
  height: 10.4rem;
}

.select-item-user button {
  padding: 1rem 2rem;
  display: block;
  border: none;
  border-bottom: 1px solid var(--color-grey);
  width: 100%;
}

.select-item-user button {
  transition: all 0.4s ease-in-out;
}

.select-item-user button.active {
  background: var(--color-green);
  color: var(--color-white);
}

/* dark model */
.rule {
  width: 6rem;
  height: 2rem;
  border-radius: 40px;
  padding: 19px 24px;
  user-select: none;
}

.rule-chek {
  transition: all 0.4s ease-in-out;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0.51rem;
}

.rule-chek span:first-child {
  margin-right: 1.5rem;
}

.rule input {
  display: none;
}

.rule-chek::before {
  content: "";
  left: 5px;
  top: 5px;
  width: 2rem;
  height: 2rem;
  background: var(--color-white);
  border-radius: var(--radius-50);
  position: absolute;
  display: block;
  transition: all 0.4s cubic-bezier(0.32, 2, 0.55, 0.27);
}

.rule-chek-input:checked~.rule-chek::before {
  left: calc(100% - 2rem - 5px);
}

.user-about .avatar-user img {
  width: 195px;
  height: 195px;
}

.user-about {
  gap: 41px;
}

.user-all-name h1 {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 44px;
  color: var(--color-black);
}

.user-all-name p {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 22px;
  margin: 1rem 0 0.21rem;
}

.editors button {
  margin-right: 13px;
  box-shadow: var(--shadow-login);
}

.all-information {
  gap: 5rem;
  margin: 3rem 0;
}

.all-information a {
  gap: 20px;
  color: none;
}

.all-information a h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.all-information a p {
  font-weight: 600;
  font-size: 1rem;
  line-height: 26px;
}

/* profile edit */
.profile-edit .grid {
  gap: 0 3rem;
}

.profile-edit a>h1 {
  padding: 1rem 0;
}

/* user crud */
.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.gap-050 {
  gap: 0.5rem;
}

.gap {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.user-item {
  padding: 1rem;
  background: var(--saidbar);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.all-information h4 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1rem;
  color: var(--color-black);
  white-space: nowrap;
}

.fill-white {
  fill: white;
}

/* line */
.hr {
  width: 100%;
  height: 4px;
  background: linear-gradient(327.46deg, #4f9274 10.31%, #70b995 65.49%);
  border-radius: 40px;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 5rem;
}

.p6 {
  padding: 6rem;
}

/* media */

@media only screen and (max-width: 1600px) {
  html {
    font-size: 13px;
  }

  .all-information {
    grid-template-columns: 100%;
    margin: 1rem 0;
  }

}

@media (max-width: 1400px) {
  .saidabr-item{
    overflow: auto;
    height: 550px;
  }
  .user-layout {
    display: none;
  }

  .user-profile {
    grid-template-columns: 100%;
  }
}

/* media max-width:1300px */

@media only screen and (max-width: 1300px) {
  /* .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  } */
}

/* hamburger */
.hamburger_btn {
  /* background: var(--color-green); */
  padding: 5px;
  border-radius: 3px;
  /* position: absolute; */
  left: -20px;
  top: 100px;
  /* display: none; */


}
.logo-media{
  opacity: 0;
  position: relative;
  z-index: -999;
}
.logo-media a{
  display: none;
}

.hamburger {
  background: var(--color-white);
  display: block;
  height: 2px;
  margin: 5px 0;
  position: relative;
  width: 40px;
  z-index: 999;
  border-radius: 5px;
}

.hamburger:last-child {
  width: 30px;
}

.load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
}

.load.active {
  display: none;
}

.select {
  width: 100%;
  border: 1px solid rgba(142, 142, 161, 0.41);
  border-radius: var(--radius);
  color: var(--color-black);
  font-size: 1.5rem;
  line-height: 22px;
  padding: 0.7rem;
}

.input-group-error {
  color: var(--color-red);
  font-size: 1.3rem;
  margin: 1rem 0;

}

/* resposive-table */
.resposive-table {
  width: 100%;
  padding: 5rem 0;
  text-align: left;
  overflow: auto;
}

.resposive-table table {
  border: 0;
}

.resposive-table table {
  width: 100%;
  font-size: 1.3rem;
}

.resposive-table table tr th,
td {
  padding: .5rem;
}

.resposive-table table tr {
  background: var(--background);

}

.border-tab {
  border: 2px solid var(--color-grey);
  border-radius: 5px;
}

/* saidabar dropdown menu */
.drop-menu {
  height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden auto;

}

.drop-menu.active {
  height: 260px;
}

.drop-menu1.active {
  height: 260px;
}

.saidabr-item a {
  position: relative;
}

/* navbar saidbar dropdown select */
#saidbar-drop-down svg {
  transform: rotate(-90deg) translateX(50%);
  transition: all 0.3s ease-in-out;
  fill: var(--color-grey) !important;
  fill: var(--color-grey) !important;
  display: block;
  margin-left: auto;
  /* transform-origin: bottom right; */
  top: 50%;
  right: 1rem;
  position: absolute;
}

.saidbar-link a.active #saidbar-drop-down svg {
  fill: white !important;
  fill: white !important;
}

#saidbar-drop-down.active svg {
  transform: rotate(0deg) translateY(calc(50% - 2rem));
}

.pagination-icon svg {
  width: 20px;
}

.pagination-dots-hover {
  position: relative;
  overflow: hidden;
}

.pagination-dots-hover span:last-child {
  position: absolute;
  transition: all 0.4s ease-in-out;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: var(--color-green);
}

.pagination-dots-hover:hover span:last-child {
  opacity: 1;
}

.pagination-item {
  user-select: none;
}

.pagination-item button {
  width: 38px;
  height: 38px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* file */
.file {
  position: relative;
  text-align: center;
}
.file video{
  height: 100px;
}

.file label .file_img {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  border: 1px dotted var(--color-grey);
}

.file input {
  display: none;
}

.file_img img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.comment_{
   /* 3qatorlik text joylab beradi */
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.table-img{
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.text-left{
  text-align: left;
}
.show h2{
  font-weight: 400;
}
.show  img{
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.icon {
  position: relative;
}
.icon img {
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  width: 35px;
  object-fit: contain;
}
.login-logo span{
  display: block !important;
  margin: auto;
  text-align: center;
}
@media(max-width:1400px){

  .login-item{
    padding: 30px 30px;
    /* width: 400px; */
  }
}

@media (max-width:1200px) {
  .home_page .grid-4{
    grid-template-columns: repeat(3, 1fr);
  }
}
.cancel{
  display: none;
}
@media (max-width:992px) {
 
  .saidbar-logo{
    display: none;
  }
  .saidbar {
    left: -22rem;
  }
  .contant{
    width: 100%;
    left: 0;
  }
  .logo-media{
    display: flex;
    gap: 1rem;
    opacity: 1;
    z-index: 1;
    align-items: center;
  }
  .logo-media a{
    display: inline-block;
  }
  .saidbar.active{
    left: 0;
  }
  .cancel{
    display: flex;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
  gap: 2rem;
  }
}
.media-bg{
  width: 100%;
  height: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  z-index: -9999;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.media-bg.active{
  z-index: 998;
  opacity: 1;
}
@media(max-width:1300px){
  .worker .grid{
    /* grid-template-columns: repeat(3, 1fr); */
  }
  .worker .file{
    grid-column: span 2 ;
  }
}
@media (max-width:776px) {
  .login-item{
    width: 500px;
  }
  .login-item h1 {
    font-weight: 500;
    font-size: 26px;  
}
  .worker .grid{
    grid-template-columns: 100%;
  }
  .worker .file{
    grid-column: span 1 ;
  }
  .user-profile-container{
    padding: 1.5rem;
  }
  .product .grid

  {
    grid-template-columns: 100%;

  }
}

@media (max-width:550px) {
  .login-item{
    width: 400px;
  }
  .home_page .grid-4 {
    grid-template-columns: repeat(2, 1fr);
}
}
@media (max-width:500px) {
  
  .category .grid{
    grid-template-columns: 100%;
  }
  .logo-media span{
    display: inline-block;
  }
  .logo-media img{
    width: calc(126px / 1.3);
  }
  .contant-avatar{
    gap: 1rem;
  }
  .w60 {
    width: 3rem;
    height: 3rem;
    max-width: 3rem;
    min-height: 3rem;
    max-height: 3rem;
    min-width: 3rem;
  }
}
.show video{
  width: 100%;
}
@media (max-width:420px) {
  .login-item{
    width: 350px;
  }
}

.pagination{
  display: flex;
  gap: 10px;
  align-items: center;
}
.pagination a{
  padding: 0.8rem;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 22px;
  border-radius: 10px; 
}
.previous{
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 22px;
  border-radius: 10px;
  background: var(--color-green);
  color: var(--bg); 
}
.next{
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 22px;
  border-radius: 10px;
  background: var(--color-green);
  color: var(--bg); 
}
.pagination li.selected a{
  background: var(--color-green);
  color: var(--bg);
  border-radius: 5px;
}